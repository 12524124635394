// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

;globalThis["_sentryRewritesTunnelPath"] = undefined;globalThis["SENTRY_RELEASE"] = {"id":"iMHxQ2jvKSx49c0pk9fn1"};globalThis["_sentryBasePath"] = undefined;globalThis["_sentryRewriteFramesAssetPrefixPath"] = "";import * as Sentry from "@sentry/nextjs"

const SENTRY_DSN =
  "https://6fcc49ff700648de8c78c19c6df700ea@o321162.ingest.sentry.io/4504711932477440"

const ignoreErrors = [
  "Maximum number of retries reached while waiting for window.ga to appear (already waited for 5500ms). window.gtag is available but it does not appear to load window.ga anymore!",
  "Failed to fetch RSC payload. Falling back to browser navigation. TypeError: Load failed",
  "Load failed",
  "TypeError: Failed to fetch",
  "TypeError: NetworkError when attempting to fetch resource",
  "UnhandledRejection: Non-Error promise rejection captured with value: undefined",
  "UET is not defined",
  "Error: Extension context invalidated.",
  "ResizeObserver loop limit exceeded",
  "Can't find variable: globalThis",
  "ResizeObserver loop completed with undelivered notifications",
  "The operation would yield an incorrect node tree.",
  "Failed to execute 'appendChild' on 'Node': Only one element on document allowed.",
  "Cannot read properties of undefined (reading 'call')",
  "undefined is not an object (evaluating 's[e].call')",
  "Maximum update depth exceeded. This can happen when a component repeatedly calls setState inside componentWillUpdate or componentDidUpdate. React limits the number of nested updates to prevent infinite loops.",
  "Non-Error exception captured",
  "Non-Error promise rejection captured",
  "Minified React error #422; visit https://react.dev/errors/422 for the full message or use the non-minified dev environment for full errors and additional helpful warnings.",
  "undefined is not an object (evaluating 'l[e].call')",
  "Unknown root exit status",
  "Unknown root exit status.",
]

Sentry.init({
  dsn:
    SENTRY_DSN ||
    "https://330410b8987f4ebe830819edc2718246@o321162.ingest.sentry.io/4504366384742400",
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 0.0125,
  replaysSessionSampleRate: 0.05,
  replaysOnErrorSampleRate: 0.125,
  sampleRate: 0.6,
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
  environment:
    process.env.NEXT_PUBLIC_STAGE ??
    process.env.NEXT_PUBLIC_VERCEL_ENV ??
    process.env.NEXT_PUBLIC_ENV_NAME,
  denyUrls: [
    // Exponea endpoints
    /exp\.baerskintactical\.com/i,
    /exp\.baerskinhoodie\.com/i,
    /exp\.divcdn\.com/i,
    /exp\.cozislides\.com/i,
    /exp\.*\.com/i,
    // Payments
    /analytics\.production\.data\.primer\.io/i,
    /www\.paypal\.com/i,
    // Clarity
    /p\.clarity\.ms/i,
    /q\.clarity\.ms/i,
    /clarity\.ms/i,
    // Facebook flakiness
    /graph\.facebook\.com/i,
    // Facebook blocked
    /connect\.facebook\.net\/en_US\/all\.js/i,
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
    /googletagmanager.com/i,
    /config.gorgias.chat/i,
    /assets.gorgias.chat/i,
    /pagead2.googlesyndication.com/i,
    // Google Adsense
    /pagead\/js/i,
    // Facebook blocked
    /connect\.facebook\.net\/en_US\/all\.js/i,
    // Woopra flakiness
    /eatdifferent\.com\.woopra-ns\.com/i,
    /static\.woopra\.com\/js\/woopra\.js/i,
    // Other plugins
    /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
    /webappstoolbarba\.texthelp\.com\//i,
    /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
    // Recart
    /api.recart\.com/i,
  ],
  integrations: [
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  ignoreErrors,
  beforeSend(event, hint) {
    const error = hint.originalException
    if (
      error &&
      error instanceof Error &&
      error.message &&
      ignoreErrors.includes(error.message)
    ) {
      const newtag = { message: error.message }
      event.tags = { ...event.tags, ...newtag }
    }
    return event
  },
})
